import React from 'react'

import './style/discount-terms.scss'
import { plans } from '../../../../../plans.js'
import { getCurrencySymbol } from 'helpers/currency-helper'

const DiscountTerms = () =>
  plans.all.adDiscount !== 0 ? (
    <div className='discount-terms container'>
      <div className='row'>
        <div className='py-5'>
          <p className='col-md-12 text-center'>
            *The promotional price is for the first term of service only and
            automatically renews at{' '}
            <a
              href={process.env.GATSBY_REGULAR_RATES}
              target='_blank'
              rel='noopener noreferrer'
            >
              regular rates
            </a>
            . For customers in regions in which VAT (Value Added Tax) or GST
            (Goods and Services Tax) is applicable, taxes are not included in
            our advertised price and will be charged separately and itemized on
            invoices and billing information. Qualified plans include a free
            domain registration for one year. If you wish to cancel, there is a
            non-refundable {getCurrencySymbol().domainPrice} domain fee.{' '}
            <a
              href={process.env.GATSBY_EU_VAT}
              target='_blank'
              rel='noopener noreferrer'
            >
              Learn more
            </a>
          </p>
        </div>
      </div>
    </div>
  ) : (
    ''
  )

export default DiscountTerms
